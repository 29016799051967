<template>
  <main works>
    <h2>WORKS</h2>
    <ul class="list">
      <li>
        <img src="/img/works-1.png" alt="">
        <div class="cont">
          <h3>CBD T <br class="hidden-tl-up">REMODELING <br class="hidden-dl-up">PROJECT</h3>
          <p>서울에 위치한 10,000평 규모의 중소형 오피스입니다. 외주부 FCU 철거 후 FIT 프레임은 4면에 적용하고, FIT 플랫폼을 적용하였습니다. 공간의 실사용 면적은 약 21% 증가하였고, 스마트 제어 시스템을 통해 에너지 소비량은 약 30% 절감되었습니다.</p>
          <ul>
            <li><p>CBD</p><p>위치</p></li>
            <li><p>2024</p><p>설계</p></li>
            <li><p>+21%</p><p>실사용 면적</p></li>
            <li><p>-30% </p><p>에너지 소비량</p></li>
          </ul>
        </div>
      </li>
      <li>
        <img src="/img/works-2.png" alt="">
        <div class="cont">
          <h3>BBD S <br class="hidden-tl-up">REMODELING <br class="hidden-dl-up">PROJECT</h3>
          <p>경기도에 위치한 5,000평 규모의 중소형 오피스입니다. 외주부 FCU 철거 후 FIT 프레임은 2면에 적용하고, FIT 플랫폼을 적용하였습니다. 공간의 실사용 면적은 약 13% 증가하였고, 스마트 제어 시스템을 통해 에너지 소비량은 약 29% 절감되었습니다.</p>
          <ul>
            <li><p>BBD</p><p>위치</p></li>
            <li><p>2023</p><p>설계</p></li>
            <li><p>+13%</p><p>실사용 면적</p></li>
            <li><p>-29% </p><p>에너지 소비량</p></li>
          </ul>
        </div>
      </li>
    </ul>
  </main>
</template>
<script>

export default {
  name: 'works',
  metaInfo() {
    return {
      title: 'FIT Works',
      meta: [
        { vmid: 'title', content: 'FIT Works', },
        { vmid: 'description', content: 'FIT Platform을 적용한 건물들을 소개합니다. 실사용 면적이 증가하였으며, 기술적인 탁월함과 에너지 소비량을 절감시켰습니다.', },
        { vmid: 'image', content: `${process.env.VUE_APP_HOST}/img/og.png`, },
        { vmid: 'path', content: this.$route.path },
      ],
    }
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
@import "~@/less/prod.less";

[works] { .pt(60);
  h2 { .p(80,0); .fs(40,1); .tc; }
  .list { .m(0, 36);
    > li { .pb(80);
      img { .block; .max-w(100%); .br(4); .crop; }
      h3 { .mt(48); .fs(32,40); }
      h3 + p { .mt(40); .fs(20,32); .c(#6e6e6e); }
      ul { .grid(2); .mt(35); grid-column-gap: 33px; .w(261); .mh-c;
        li { .p(25,0); .tc;
          p { .fs(24,1); .bold; }
          p + p { .mt(12); .fs(12,1); .c(#666); }
        }
        li:nth-child(n+3) { .-t(#DADADA); }
      }
    }
    > li + li { .mt(40); .pb(128); }
  }
}

@media (min-width: @screen-tp-min) {
  [works] {
    .list { .w(392); .mh-c;
      > li {
        .cont { .p(0,44); }
        h3 { .tl; }
        h3 + p { .tl; }
      }
    }
  }
}
@media (min-width: @screen-tl-min) {
  [works] {
    h2 { .p(112,0,132); .fs(64,1); }
    .list { .m(0, 80); .w(auto);
      > li { .flex; .pb(100); align-items: center;
        img { .w(392); flex: 1 0 auto; }
        .cont { .p; .pl(80); }
        h3 { .mt(0); }
        ul { .flex; .mt(40); .w(auto); grid-column-gap: 0;
          li { .p(14,0); flex: 1 1 auto;
            p { .fs(32,1); }
            p + p { .fs(16,1); }
          }
          li:nth-child(n+3) { border-top: 0; }
          li + li { .-l(#DADADA); }
        }
      }
      > li + li { .mt(0); }
    }
  }
}
@media (min-width: @screen-ds-min) {
  [works] { .pt(80);
    .list {
      > li {
        img { .w(520); }
      }
    }
  }
}
@media (min-width: @screen-dl-min) {
  [works] { .w(1600); .mh-c;
    .list {
      > li {
        img { .w(680); }
      }
    }
  }
}
</style>
